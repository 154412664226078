import React from "react"
import { graphql } from "gatsby"

import Mailto from "react-protected-mailto"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialSharing from "../components/socialsharing"
import MailChimp from "../components/mailchimp"
import PopularPosts from "../components/popularposts"

class AdvertisingPolicy extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Privacy Policy" />
        <div className="col-lg-8 col-sm-12">
          <h1>Privacy Policy</h1>
          <p>
            <em>Is it time to</em> maintains a privacy policy regarding any information we may collect from you across our website.
            </p><p>
            <em>Is it time to</em> only asks for personal information as is needed to provide the services on our website. This site
            is monitored by Google Analytics and Adwords. Google may acquire information about users of this site which can be used
            by us as information on site usage.
            </p><p>
            <em>Is it time to</em> retains collected information only for as long as necessary to provide you with the services on this website. All reasonable steps have been taken to prevent loss and theft, unauthorised access, disclosure, copying, use, or modification of this information.
            </p><p>
            <em>Is it time to</em> never shares any personally identifying information except where required to by law.
            </p><p>
            <em>Is it time to</em> may link to external services that are not operated by us. <em>Is it time to</em> cannot accept responsibility or liability for the privacy policy or practices of these sites.
            </p><p>
            Use of this website will be regarded as acceptance of our practices regarding privacy and personal information. If you have any questions about how we handle user data and personal information, please contact us here: <Mailto email="privacy@isittimeto.com"></Mailto>
            </p><p>
            This policy is effective as of 1 April, 2019.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
            <MailChimp />
            <PopularPosts />
        </div>
      </Layout>
    )
  }
}

export default AdvertisingPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`